<template>
  <div class="bg-white p-3 rounded shadow-sm">
    <h2>Rekapitulasi Prolis</h2>
    <b-row class="mb-3">
      <b-col cols="12" md="6">
        <b-form-group label="Bulan" label-for="bulan">
          <b-form-select
            id="bulan"
            v-model="selectedBulan"
            :options="bulanOptions"
            @change="fetchData"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Tahun" label-for="tahun">
          <b-form-select
            id="tahun"
            v-model="selectedTahun"
            :options="tahunOptions"
            @change="fetchData"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <table
      class="table table-bordered table-striped table-hover table-sm mb-0 text-center"
    >
      <thead>
        <tr>
          <th rowspan="2">TANGGAL PROLIS</th>
          <th colspan="4">LP</th>
          <th colspan="4">PRC</th>
          <th colspan="4">TC</th>
          <th rowspan="2">Jumlah</th>
        </tr>
        <tr>
          <th>GOL A POS</th>
          <th>GOL B POS</th>
          <th>GOL AB POS</th>
          <th>GOL O POS</th>
          <th>GOL A POS</th>
          <th>GOL B POS</th>
          <th>GOL AB POS</th>
          <th>GOL O POS</th>
          <th>GOL A POS</th>
          <th>GOL B POS</th>
          <th>GOL AB POS</th>
          <th>GOL O POS</th>
        </tr>
      </thead>
      <tbody>
        <!-- Iterasi Data dari API -->
        <tr v-for="(item, index) in parsedDataTable" :key="index">
          <td>{{ item.date }}</td>
          <!-- LP -->
          <td>{{ item.LP["A Pos"] || 0 }}</td>
          <td>{{ item.LP["B Pos"] || 0 }}</td>
          <td>{{ item.LP["AB Pos"] || 0 }}</td>
          <td>{{ item.LP["O Pos"] || 0 }}</td>
          <!-- PRC -->
          <td>{{ item.PRC["A Pos"] || 0 }}</td>
          <td>{{ item.PRC["B Pos"] || 0 }}</td>
          <td>{{ item.PRC["AB Pos"] || 0 }}</td>
          <td>{{ item.PRC["O Pos"] || 0 }}</td>
          <!-- TC -->
          <td>{{ item.TC["A Pos"] || 0 }}</td>
          <td>{{ item.TC["B Pos"] || 0 }}</td>
          <td>{{ item.TC["AB Pos"] || 0 }}</td>
          <td>{{ item.TC["O Pos"] || 0 }}</td>
          <td>{{ item.total }}</td>
        </tr>

        <!-- Baris Total -->
        <tr class="bg-light text-bold">
          <td><strong>Total</strong></td>
          <!-- LP -->
          <td>
            <strong>{{ total.LP["A Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.LP["B Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.LP["AB Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.LP["O Pos"] || 0 }}</strong>
          </td>
          <!-- PRC -->
          <td>
            <strong>{{ total.PRC["A Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.PRC["B Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.PRC["AB Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.PRC["O Pos"] || 0 }}</strong>
          </td>
          <!-- TC -->
          <td>
            <strong>{{ total.TC["A Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.TC["B Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.TC["AB Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ total.TC["O Pos"] || 0 }}</strong>
          </td>
          <td>
            <strong>{{ totalKeseluruhan }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { BCol, BFormGroup, BFormSelect, BRow } from "bootstrap-vue";
import productReleaseAPI from "../../../api/product_releases/productReleaseAPI";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
  },
  data() {
    return {
      selectedBulan: new Date().getMonth() + 1,
      selectedTahun: new Date().getFullYear(),
      bulanOptions: [
        { value: null, text: "Pilih Bulan" },
        { value: 1, text: "Januari" },
        { value: 2, text: "Februari" },
        { value: 3, text: "Maret" },
        { value: 4, text: "April" },
        { value: 5, text: "Mei" },
        { value: 6, text: "Juni" },
        { value: 7, text: "Juli" },
        { value: 8, text: "Agustus" },
        { value: 9, text: "September" },
        { value: 10, text: "Oktober" },
        { value: 11, text: "November" },
        { value: 12, text: "Desember" },
      ],
      tahunOptions: [...Array(new Date().getFullYear() - 2021 + 1).keys()].map(
        (i) => ({
          value: 2023 + i,
          text: (2023 + i).toString(),
        })
      ),
      dataTable: [],
      parsedDataTable: [],
      total: {
        LP: {
          "A Pos": 0,
          "B Pos": 0,
          "AB Pos": 0,
          "O Pos": 0,
        },
        PRC: {
          "A Pos": 0,
          "B Pos": 0,
          "AB Pos": 0,
          "O Pos": 0,
        },
        TC: {
          "A Pos": 0,
          "B Pos": 0,
          "AB Pos": 0,
          "O Pos": 0,
        },
      },
      totalKeseluruhan: 0,
    };
  },
  methods: {
    async fetchData() {
      const { data: response } = await productReleaseAPI.getDaily({
        month: this.selectedBulan,
        year: this.selectedTahun,
      });

      this.dataTable = response.data.detail;
      this.parseDataTable();
      this.calculateTotal();
    },
    parseDataTable() {
      this.parsedDataTable = this.dataTable.map((item) => {
        const total =
          (item.LP_total || 0) + (item.PRC_total || 0) + (item.TC_total || 0);

        return {
          date: item.date,
          LP: item.LP || {
            "A Pos": 0,
            "B Pos": 0,
            "AB Pos": 0,
            "O Pos": 0,
          },
          PRC: item.PRC || {
            "A Pos": 0,
            "B Pos": 0,
            "AB Pos": 0,
            "O Pos": 0,
          },
          TC: item.TC || {
            "A Pos": 0,
            "B Pos": 0,
            "AB Pos": 0,
            "O Pos": 0,
          },
          total,
        };
      });
    },
    calculateTotal() {
      // Reset total sebelum menghitung ulang
      this.total = {
        LP: {
          "A Pos": 0,
          "B Pos": 0,
          "AB Pos": 0,
          "O Pos": 0,
        },
        PRC: {
          "A Pos": 0,
          "B Pos": 0,
          "AB Pos": 0,
          "O Pos": 0,
        },
        TC: {
          "A Pos": 0,
          "B Pos": 0,
          "AB Pos": 0,
          "O Pos": 0,
        },
      };
      this.totalKeseluruhan = 0;

      // Iterasi setiap item di parsedDataTable dan jumlahkan nilai-nilai LP, PRC, TC
      this.parsedDataTable.forEach((item) => {
        // LP
        this.total.LP["A Pos"] += item.LP["A Pos"] || 0;
        this.total.LP["B Pos"] += item.LP["B Pos"] || 0;
        this.total.LP["AB Pos"] += item.LP["AB Pos"] || 0;
        this.total.LP["O Pos"] += item.LP["O Pos"] || 0;

        // PRC
        this.total.PRC["A Pos"] += item.PRC["A Pos"] || 0;
        this.total.PRC["B Pos"] += item.PRC["B Pos"] || 0;
        this.total.PRC["AB Pos"] += item.PRC["AB Pos"] || 0;
        this.total.PRC["O Pos"] += item.PRC["O Pos"] || 0;

        // TC
        this.total.TC["A Pos"] += item.TC["A Pos"] || 0;
        this.total.TC["B Pos"] += item.TC["B Pos"] || 0;
        this.total.TC["AB Pos"] += item.TC["AB Pos"] || 0;
        this.total.TC["O Pos"] += item.TC["O Pos"] || 0;

        // Jumlahkan total keseluruhan dari setiap total komponen
        this.totalKeseluruhan += item.total || 0;
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
